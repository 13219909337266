* {
  margin: 0;
}

@font-face {
  font-family: BespokeIT;
  src: url(../public/media/BespokeSerif-LightItalic.otf);
}

@font-face {
  font-family: Tanker;
  src: url(../public/media/Tanker-Regular.otf);
}

body {
  height: 110dvh;
  width: 100dvw;
  background-color: #f1f1f1;
}

body::-webkit-scrollbar {
  display: none;
}

.container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1;
  /* background: linear-gradient(#d4d4d4, #f1f1f1); */
}

.video {
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 90%;
  max-width: 800px;
  object-fit: contain;
  filter: contrast(1) brightness(1.6);
}

.header {
  z-index: 1;
  position: fixed;
  color: #343434;
  font-family: Tanker;
  margin: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: color 1s;
}

.hidden {
  position: fixed;
  height: 100vh;
  width: 100vw;
  bottom: 0;
  background-image: linear-gradient(
    180deg,
    hsl(0deg 0% 0%) 0%,
    hsl(0deg 0% 2%) 50%,
    hsl(0deg 0% 5%) 65%,
    hsl(0deg 0% 6%) 100%
  );
  transition: transform 1s;
}

.temp {
  font-family: BespokeIT;
  font-size: 0.7rem;
  position: fixed;
  bottom: 0;
  margin: 0.5rem;
  transition: color 1.5s;
}
